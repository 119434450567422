import React from 'react'
import AddUser from '../Components/Admin/AddUser'
function addUser() {
  return (
    <div className="page-body">
      <AddUser/>
    </div>
  )
}

export default addUser