import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { Row } from 'reactstrap'

const initialState = {
    first_name: "",
    last_name: "",
    email: ""
}

function ResetPassword() {

    const [state, setState] = useState(initialState)
    const [passwordState, setPasswordState] = useState({ password: "", confirm_password: "" });
    const navigate = useNavigate()
    const { first_name, last_name } = state
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getSingleUser(id);
        }
    }, [id])

    const getSingleUser = async (id) => {
        const response = await axios.get(`https://api.payrollappcoach.com/serve/edit/${id}`);
        if (response.status === 200) {

            setState({ ...response.data })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (id) {
            updateUser(passwordState, id)
        }

    }

    const updateUser = async (data, id) => {
        try {
            console.log(data);

            if (data.password !== data.confirm_password) {
                return generateError("Passwords do not match")
            }

            const response = await axios.post(`https://api.payrollappcoach.com/serve/reset-password/${id}`, data)


            if (response) {
                if (response.data.errors) {
                    const { name, email } = response.data.errors;
                    if (name) generateError(name)
                    else if (email) generateError(email)
                } else {
                    navigate(`${process.env.PUBLIC_URL}/admin_panel`)
                }
            }


        } catch (error) {
            console.log(error);
        }

    }


    const generateError = (err) =>
        toast.error(err, {
            position: "top-center",
        });


    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setPasswordState({ ...passwordState, [name]: value });
    }

    const onCancel = () => {
        navigate(`${process.env.PUBLIC_URL}/admin_panel`);
    }

    return (
        <div className="page">
            <div className='container'>
                <h2>Reset Password for {first_name + " " + last_name}</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">New Password</label>
                        <input
                            type="password"
                            name='password'
                            className="form-control mt-1"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="name">Confirm Password</label>
                        <input
                            type="password"
                            name='confirm_password'
                            className="form-control mt-1"
                            onChange={handleInputChange}
                        />
                    </div>
                    <Row>
                        <div className="col-6">
                            <button type='submit' className="form-control mt-3 btn-secondary" style={{ color: '#ffffff' }}>Reset Password</button>
                        </div>
                        <div className="col-6">
                            <button onClick={onCancel} className="form-control mt-3 btn-danger">Cancel</button>
                        </div>
                    </Row>
                </form>
                <ToastContainer />
            </div>
        </div>
    )
}

export default ResetPassword
