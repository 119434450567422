import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Row } from 'reactstrap'
import Papa from 'papaparse'

function BulkAddUser() {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();


    const generateError = (err) =>
        toast.error(err, {
            position: "top-center",
        });

    const sendToServer = async (userData) => {
        try {
            const { data } = await axios.post("https://api.payrollappcoach.com/serve/bulk_add_user", {
                userData
            }, {
                withCredentials: true,
            });
            if (data) {
                if (!data.errors) {
                    navigate(`${process.env.PUBLIC_URL}/admin_panel`);
                    toast.success("Added Successfully...");
                } else {
                    toast.error("Error importing users. Please try again later.")
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    const onSubmit = async (values) => {
        Papa.parse(values.file[0], {
            complete: (result) => {
                sendToServer(result.data);
            },
            header: true,
        });
    }

    const onCancel = () => {
        navigate(`${process.env.PUBLIC_URL}/admin_panel`);
    }


    return (
        <div className="page">
            <div className='container'>
                <h2>Bulk Import Users</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="username">CSV File</label>
                        <input
                            type="file"
                            name='file'
                            className="form-control mt-1"
                            placeholder='File'
                            {...register("file", { required: "File is required" })}
                        />
                        {errors.file && <p style={{ color: "red" }}>{errors.file.message}</p>}
                    </div>

                    <Row>
                        <div className="col-6">
                            <button type='submit' className="form-control mt-3 btn-success">Import</button>
                        </div>
                        <div className="col-6">
                            <button onClick={onCancel} className="form-control mt-3 btn-danger">Cancel</button>
                        </div>
                    </Row>

                </form>
                <ToastContainer />
            </div>
        </div>
    )



}

export default BulkAddUser