import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Row } from 'reactstrap'

function AddEmployee() {
    const [status, setStatus] = useState("Active");
    const [payType, setPayType] = useState("Salary");
    const [elRate, setElRate] = useState("Regular");
    const [companyCode, setCompanyCode] = useState("4JT");
    const navigate = useNavigate();
    const {register , handleSubmit, formState: { errors }} = useForm();
    
    
    const generateError = (err) =>
        toast.error(err, {
          position: "top-center",
        });  
    
    
    const onSubmit = async (values) => {    
      try {
        const { data } = await axios.post("https://api.payrollappcoach.com/serve/add_employee", {
          status,
          pay_type: payType,
          el_rate: elRate,
          company_code: companyCode,
          ...values,
        }, {
          withCredentials: true,
        })
          
        if (data) {
          if (data.errors) {
            generateError(data.errors)
          } else { 
            if(data.created==true){
              navigate(`${process.env.PUBLIC_URL}/admin_panel/employee_list`);
              toast.success("Added Successfully...");
            }else{
              generateError("Failed...")
            }   
            
          }
        }
    
      } catch (error) {
        console.log(error);
      }    
    }

    const onCancel=()=>{
      navigate(`${process.env.PUBLIC_URL}/admin_panel/employee_list`);
    }
    

      return (
        <div className="page">
        <div className='container'>
          <h2>Add New Employee</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                name='first_name'
                className="form-control mt-1"
                placeholder='First Name'
                {...register("first_name",{ required: "First Name is required",maxLength:{
                  value:20,
                  message:"First Name cannot exceed more than 20 characters"
                } })}
                 />
            {errors.first_name && <p style={{color: "red"}}>{errors.first_name.message}</p>}
            </div>
            <div>
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                name='last_name'
                className="form-control mt-1"
                placeholder='Last Name'
                {...register("last_name",{ required: "Last Name is required",maxLength:{
                  value:20,
                  message:"Last Name cannot exceed more than 20 characters"
                } })}
                 />
            {errors.last_name && <p style={{color: "red"}}>{errors.last_name.message}</p>}
            </div>
            
            <div>
              <label htmlFor="age">Age</label>
              <input
                type="age"
                name='age'
                className="form-control mt-1"
                placeholder='Age'
                {...register("age",{ required: "Age is required", pattern:{value:/^[0-9.]+$/i, message:"Enter valid Age"}})}
                 />
            {errors.age && <p style={{color: "red"}}>{errors.age.message}</p>}
            </div>
            <div>
              <label htmlFor="salary">Salary</label>
              <input
                type="salary"
                name='salary'
                className="form-control mt-1"
                placeholder='Salary'
                {...register("salary",{ required: "Salary is required", pattern:{value:/^[0-9.]+$/i, message:"Enter valid Salary"}})}
                 />
            {errors.salary && <p style={{color: "red"}}>{errors.salary.message}</p>}
            </div>
            <div>
              <label htmlFor="company_code">Company Code</label>
              <select defaultValue={companyCode} onChange={(e) => setCompanyCode(e.target.value)} className='mt-1 form-control'>
                <option value="4JT">4JT</option>
                <option value="2RY">2RY</option>
                <option value="7M5">7M5</option>
              </select>
            </div>
              <div>
              <label htmlFor="status">Status</label>
              <select defaultValue={status} onChange={(e) => setStatus(e.target.value)} className='mt-1 form-control'>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
              <div>
              <label htmlFor="payType">Status</label>
              <select defaultValue={payType} onChange={(e) => setPayType(e.target.value)} className='mt-1 form-control'>
                <option value="Salary">Salary</option>
                <option value="Hourly">Hourly</option>
              </select>
            </div>
            <div>
              <label htmlFor="file">File No.</label>
              <input
                type="text"
                name='file'
                className="form-control mt-1"
                placeholder='File No.'
                {...register("file")}
                 />
            {errors.file && <p style={{color: "red"}}>{errors.file.message}</p>}
            </div>
            <div>
              <label htmlFor="residence_province">Residence Province</label>
              <input
                type="text"
                name='residence_province'
                className="form-control mt-1"
                placeholder='Residence Province'
                {...register("residence_province")}
                 />
            {errors.residence_province && <p style={{color: "red"}}>{errors.residence_province.message}</p>}
            </div>
            <div>
              <label htmlFor="start_date">Start Date</label>
              <input
                type='date'
                name='start_date'
                className="form-control mt-1"
                placeholder='Start Date'
                {...register("start_date")}
              />
            </div>
            <div>
              <label htmlFor="location">Location</label>
              <input
                type="text"
                name='location'
                className="form-control mt-1"
                placeholder='Location'
                {...register("location")}
                 />
            {errors.location && <p style={{color: "red"}}>{errors.location.message}</p>}
            </div>
            <div>
              <label htmlFor="employment_province">Employment Province</label>
              <input
                type="text"
                name='employment_province'
                className="form-control mt-1"
                placeholder='Employment Province'
                {...register("employment_province")}
                 />
            {errors.employment_province && <p style={{color: "red"}}>{errors.employment_province.message}</p>}
            </div>
            <div>
              <label htmlFor="federal_tax">Federal Tax</label>
              <input
                type="number"
                name='federal_tax'
                className="form-control mt-1"
                placeholder='Federal Tax'
                {...register("federal_tax")}
                 />
            {errors.federal_tax && <p style={{color: "red"}}>{errors.federal_tax.message}</p>}
            </div>
            <div>
              <label htmlFor="additional_tax">Additional Tax</label>
              <input
                type="number"
                name='additional_tax'
                className="form-control mt-1"
                placeholder='Additional Tax'
                {...register("additional_tax")}
                 />
            {errors.additional_tax && <p style={{color: "red"}}>{errors.additional_tax.message}</p>}
            </div>
            <div>
              <label htmlFor="elRate">El Rate</label>
              <select defaultValue={elRate} onChange={(e) => setElRate(e.target.value)} className='mt-1 form-control'>
                <option value="Regular">Regular</option>
                <option value="Reduced">Reduced</option>
              </select>
            </div>

            <Row>
              <div className="col-6">
                <button type='submit' className="form-control mt-3 btn-success">Add</button>
              </div>
              <div className="col-6">
                <button onClick={onCancel} className="form-control mt-3 btn-danger">Cancel</button>
              </div>
            </Row>
            
            
            
          </form>
          <ToastContainer />
        </div>
        </div>
      )
    


}

export default AddEmployee











// return (
//     <div className="page">
//     <div className='container'>
//       <h2>Add New User</h2>
//       <form >
//         <div>
//           <label htmlFor="name">Name</label>
//           <input
//             type="text"
//             name='name'
//             placeholder='Name'
//              />
//         </div>
//         <div>
//           <label htmlFor="email">Email</label>
//           <input
//             type="email"
//             name='email'
//             placeholder='Email'
//              />
//         </div>
//         <div>
//           <label htmlFor="email">Password</label>
//           <input
//             type="password"
//             name='password'
//             placeholder='Password'
//              />
//         </div>
        
//         <button type='submit' style={{backgroundColor:"green"}}>Update</button>
//       </form>
//       {/* <ToastContainer /> */}
//     </div>
//     </div>
//   )