import React from 'react'
import EmployeeList from '../Components/Employee/EmployeeList'
function employeeList() {
  return (
    <div className="page-body">
      <EmployeeList/>
    </div>
  )
}

export default employeeList