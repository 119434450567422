import React from "react";
import { CardBody, Col, Container, Row } from "reactstrap";
import LoginPage from "./LoginPage";
import Logo from "../assets/images/logo/WLogo.png";

const Logins = () => {
  return (
    <div className="page-wrapper compact-wrapper">
      <Container fluid className="p-0">
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright auth-bg">
                <div className="authentication-box">
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                  <img src={Logo} alt="logo" style={{ objectFit: "contain" }} />
                  </div>
                  <div className="mt-4">
                    <CardBody className="p-0">
                      <LoginPage />
                    </CardBody>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Logins;
