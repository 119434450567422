import ResetPassword from '../Components/Admin/ResetPassword'

function ResetPasswordPage() {
    return (
        <div className="page-body">
            <ResetPassword />
        </div>
    )
}

export default ResetPasswordPage