import React from 'react'
import BulkAddUser from '../Components/Admin/BulkAddUser'
function BulkAddUserPage() {
  return (
    <div className="page-body">
      <BulkAddUser/>
    </div>
  )
}

export default BulkAddUserPage