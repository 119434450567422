import React, { Fragment } from 'react'
import Home from '../Components/Home/Home'
// import Nav from '../Components/Navbar/Unav';
function userHome() {
  return (
    <div className="page-body">
     {/* <Nav/> */}
      <Home />
    </div>
  )
}

export default userHome;