import React from 'react'
import ManagePayroll from '../Components/Payroll/Manage'
function managePayroll() {
  return (
    <div className="page-body">
      <ManagePayroll/>
    </div>
  )
}

export default managePayroll