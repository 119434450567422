import React from 'react'
import EditUser from '../Components/Admin/EditUser'

function editUser() {
  return (
    <div className="page-body">
    <EditUser/>
    </div>
  )
}

export default editUser