import React from 'react'
import AddEmployee from '../Components/Admin/AddEmployee'
function addEmployee() {
  return (
    <div className="page-body">
      <AddEmployee/>
    </div>
  )
}

export default addEmployee